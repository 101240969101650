import React, { useCallback, useEffect, useState } from 'react';

import { Link, useHistory, useParams } from 'react-router-dom';
import { SlArrowLeft } from 'react-icons/sl';
import { Container } from './styles';
import logoDashboard from '~/assets/default/card-dashboard.png';
import logoData from '~/assets/default/card-dados.png';
import logoProjects from '~/assets/default/card-projetos.png';
import api from '~/services/api';
import NoData from '~/components/NoData';

interface IProjectResponse {
  id: number;
  title: string;
  slug: string;
}

interface IProject {
  id: number;
  logo: string;
  title: string;
  adress: string;
  project: string;
  slug: string;
}

interface ICategory {
  name: string;
  path: string;
  projects: IProject[];
}

interface IParams {
  category: string;
}

const YourWorks: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [projects, setProjects] = useState<IProject[]>([]);
  const [projectId, setProjectId] = useState(0);
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    if (params.category !== 'todos') {
      let category = 'dashboards';
      let logo = logoDashboard;
      let projectData = 'Dashboards';
      if (params.category === 'analise-dados') {
        category = 'analysis';
        logo = logoData;
        projectData = 'Análise de dados';
      }

      if (params.category === 'projetos') {
        category = 'projects';
        logo = logoProjects;
        projectData = 'Projetos personalizados';
      }

      api
        .get<IProjectResponse[]>(`projects/categories/${category}`)
        .then((response) => {
          const data = response.data.map((project) => ({
            id: project.id,
            logo,
            title: project.title,
            adress: 'São Paulo/SP',
            project: projectData,
            slug: project.slug,
          }));
          setProjects(data);
        });

      setTitle(projectData);
    } else {
      const categoriesData: ICategory[] = [
        {
          name: 'Dashboards',
          path: 'dashboards',
          projects: [],
        },
        {
          name: 'Análise de dados',
          path: 'analise-dados',
          projects: [],
        },
        {
          name: 'Projetos personalizados',
          path: 'projetos',
          projects: [],
        },
      ];
      api
        .get<IProjectResponse[]>(`projects/categories/dashboards`)
        .then(async (response) => {
          const data = response.data.map((project) => ({
            id: project.id,
            logo: logoDashboard,
            title: project.title,
            adress: 'São Paulo/SP',
            project: 'Dashboards',
            slug: project.slug,
          }));
          categoriesData[0].projects = data;

          const responseAnalysis = await api.get<IProjectResponse[]>(
            `projects/categories/analysis`
          );
          const dataAnalysis = responseAnalysis.data.map((project) => ({
            id: project.id,
            logo: logoData,
            title: project.title,
            adress: 'São Paulo/SP',
            project: 'Análise de dados',
            slug: project.slug,
          }));
          categoriesData[1].projects = dataAnalysis;

          const responseProjects = await api.get<IProjectResponse[]>(
            `projects/categories/projects`
          );
          const dataProjects = responseProjects.data.map((project) => ({
            id: project.id,
            logo: logoProjects,
            title: project.title,
            adress: 'São Paulo/SP',
            project: 'Projetos personalizados',
            slug: project.slug,
          }));
          categoriesData[2].projects = dataProjects;

          setCategories(categoriesData);
        });
    }
  }, [params.category]);

  const handleMouseEnter = useCallback((project_id) => {
    setProjectId(project_id);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setProjectId(0);
  }, []);

  return (
    <Container className="">
      <div className="container position-relative">
        <div className="line l-4" />
        <div className="line l-50" />
        <div className="line l-96" />
        <div className="row">
          <div className="col-12 mb-4 mt-5 px-5 px-lg-3">
            <h2 className="d-flex align-items-center fw-normal text-white display-5 mt-5 pt-5">
              <button
                onClick={() => history.goBack()}
                type="button"
                className="bg-transparent border-0 me-4"
              >
                <SlArrowLeft size={25} color="#fff" />
              </button>{' '}
              Alguns trabalhos da Muove
            </h2>
          </div>
        </div>
        {params.category === 'todos' ? (
          <>
            {categories.map((category) => (
              <div key={category.name} className="row">
                <div className="col-12 mb-4 px-5 px-lg-3">
                  <h3 className="fw-normal text-white mt-5">{category.name}</h3>
                </div>
                {category.projects.length > 0 ? (
                  <>
                    {category.projects.map((project) => (
                      <div
                        key={project.id}
                        className="col-md-6 col-lg-4 px-4 px-lg-3 mb-5"
                      >
                        <Link
                          className="position-relative d-flex h-100 w-100"
                          to={`${process.env.PUBLIC_URL}/portifolio/${category.path}/${project.slug}`}
                          onMouseEnter={() => handleMouseEnter(project.id)}
                          onMouseLeave={() => handleMouseLeave()}
                        >
                          <svg
                            width="402"
                            height="452"
                            viewBox="0 0 402 452"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1098_1171)">
                              <path
                                d="M62.9369 20.8698L35.3695 37.9071C21.8077 46.2885 13.5527 61.0946 13.5527 77.0373V415C13.5527 435.435 30.1182 452 50.5527 452H270.397C282.684 452 294.641 448.03 304.488 440.682L378.644 385.341C393.061 374.582 401.553 357.649 401.553 339.66V51C401.553 30.5655 384.987 14 364.553 14L87.1201 14C78.5775 14 70.2037 16.3788 62.9369 20.8698Z"
                                fill="black"
                              />
                              <path
                                opacity="0.4"
                                d="M190.353 -59.1979C159.839 -170.956 -65.9697 -94.4499 -93.885 9.73139C-121.222 128.289 155.408 132.509 190.617 -28.7482C192.802 -38.7539 193.05 -49.318 190.353 -59.1979Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeDasharray="10 10"
                              />
                              <g clipPath="url(#clip1_1098_1171)">
                                <circle
                                  cx="-33.9473"
                                  cy="503.5"
                                  r="273"
                                  stroke="white"
                                />
                                <circle
                                  cx="-34.4473"
                                  cy="503"
                                  r="260.5"
                                  stroke="white"
                                />
                                <circle
                                  cx="-34.9473"
                                  cy="502.5"
                                  r="248"
                                  stroke="white"
                                />
                                <circle
                                  cx="-35.4473"
                                  cy="502"
                                  r="235.5"
                                  stroke="white"
                                />
                                <circle
                                  cx="-35.9473"
                                  cy="501.5"
                                  r="223"
                                  stroke="white"
                                />
                                <circle
                                  cx="-36.4473"
                                  cy="501"
                                  r="210.5"
                                  stroke="white"
                                />
                                <circle
                                  cx="-36.9473"
                                  cy="500.5"
                                  r="198"
                                  stroke="white"
                                />
                                <circle
                                  cx="-37.4473"
                                  cy="500"
                                  r="185.5"
                                  stroke="white"
                                />
                                <circle
                                  cx="-37.9473"
                                  cy="499.5"
                                  r="173"
                                  stroke="white"
                                />
                                <circle
                                  cx="-38.4473"
                                  cy="499"
                                  r="160.5"
                                  stroke="white"
                                />
                                <circle
                                  cx="-38.9473"
                                  cy="498.5"
                                  r="148"
                                  stroke="white"
                                />
                                <circle
                                  cx="-39.4473"
                                  cy="498"
                                  r="135.5"
                                  stroke="white"
                                />
                                <circle
                                  cx="-39.9473"
                                  cy="497.5"
                                  r="123"
                                  stroke="white"
                                />
                                <circle
                                  cx="-40.4473"
                                  cy="497"
                                  r="110.5"
                                  stroke="white"
                                />
                                <circle
                                  cx="-40.9473"
                                  cy="496.5"
                                  r="98"
                                  stroke="white"
                                />
                                <circle
                                  cx="-41.4473"
                                  cy="496"
                                  r="85.5"
                                  stroke="white"
                                />
                              </g>
                            </g>
                            <path
                              d="M49.9369 7.86984L22.3695 24.9071C8.8077 33.2885 0.552734 48.0946 0.552734 64.0373V402C0.552734 422.435 17.1182 439 37.5527 439H257.397C269.684 439 281.641 435.03 291.488 427.682L365.644 372.341C380.061 361.582 388.553 344.649 388.553 326.66V38C388.553 17.5655 371.987 1.00004 351.553 1.00004L74.1201 1C65.5775 1 57.2037 3.3788 49.9369 7.86984Z"
                              style={{
                                transitionDuration: '0.3s',
                                stroke:
                                  projectId === project.id
                                    ? '#4E4E4E'
                                    : 'transparent',
                              }}
                            />
                            <rect
                              x="311.553"
                              y="360"
                              width="72"
                              height="72"
                              rx="36"
                              style={{
                                transitionDuration: '0.3s',
                                fill:
                                  projectId === project.id
                                    ? '#707070'
                                    : '#202020',
                              }}
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M349.475 385.922C349.904 385.492 350.601 385.492 351.03 385.922L359.831 394.722C360.26 395.151 360.26 395.848 359.831 396.277L351.03 405.078C350.601 405.507 349.904 405.507 349.475 405.078C349.045 404.648 349.045 403.951 349.475 403.522L356.397 396.6H337.053C336.445 396.6 335.953 396.107 335.953 395.5C335.953 394.892 336.445 394.4 337.053 394.4H356.397L349.475 387.477C349.045 387.048 349.045 386.351 349.475 385.922Z"
                              fill="white"
                            />
                            <defs>
                              <clipPath id="clip0_1098_1171">
                                <rect
                                  width="388"
                                  height="438"
                                  fill="white"
                                  transform="translate(13.5527 14)"
                                />
                              </clipPath>
                              <clipPath id="clip1_1098_1171">
                                <rect
                                  x="-13.4473"
                                  y="353"
                                  width="69"
                                  height="69"
                                  rx="34.5"
                                  fill="white"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <img
                            className="position-absolute"
                            src={project.logo}
                            alt="Project"
                          />

                          <h4 className="position-absolute h5 h4-xxl text-white fw-lighter">
                            {project.title}
                          </h4>
                        </Link>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <NoData message="Nenhum projeto cadastrado" />
                  </>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="row">
            <div className="col-12 mb-4 px-5 px-lg-3">
              <h3 className="fw-normal text-white mt-5">{title}</h3>
            </div>

            {projects.length > 0 ? (
              <>
                {projects.map((project) => (
                  <div
                    key={project.id}
                    className="col-md-6 col-lg-4 px-4 px-lg-3 mb-5"
                  >
                    <Link
                      className="position-relative d-flex h-100 w-100"
                      to={`${process.env.PUBLIC_URL}/portifolio/${params.category}/${project.slug}`}
                      onMouseEnter={() => handleMouseEnter(project.id)}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <svg
                        width="402"
                        height="452"
                        viewBox="0 0 402 452"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1098_1171)">
                          <path
                            d="M62.9369 20.8698L35.3695 37.9071C21.8077 46.2885 13.5527 61.0946 13.5527 77.0373V415C13.5527 435.435 30.1182 452 50.5527 452H270.397C282.684 452 294.641 448.03 304.488 440.682L378.644 385.341C393.061 374.582 401.553 357.649 401.553 339.66V51C401.553 30.5655 384.987 14 364.553 14L87.1201 14C78.5775 14 70.2037 16.3788 62.9369 20.8698Z"
                            fill="black"
                          />
                          <path
                            opacity="0.4"
                            d="M190.353 -59.1979C159.839 -170.956 -65.9697 -94.4499 -93.885 9.73139C-121.222 128.289 155.408 132.509 190.617 -28.7482C192.802 -38.7539 193.05 -49.318 190.353 -59.1979Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeDasharray="10 10"
                          />
                          <g clipPath="url(#clip1_1098_1171)">
                            <circle
                              cx="-33.9473"
                              cy="503.5"
                              r="273"
                              stroke="white"
                            />
                            <circle
                              cx="-34.4473"
                              cy="503"
                              r="260.5"
                              stroke="white"
                            />
                            <circle
                              cx="-34.9473"
                              cy="502.5"
                              r="248"
                              stroke="white"
                            />
                            <circle
                              cx="-35.4473"
                              cy="502"
                              r="235.5"
                              stroke="white"
                            />
                            <circle
                              cx="-35.9473"
                              cy="501.5"
                              r="223"
                              stroke="white"
                            />
                            <circle
                              cx="-36.4473"
                              cy="501"
                              r="210.5"
                              stroke="white"
                            />
                            <circle
                              cx="-36.9473"
                              cy="500.5"
                              r="198"
                              stroke="white"
                            />
                            <circle
                              cx="-37.4473"
                              cy="500"
                              r="185.5"
                              stroke="white"
                            />
                            <circle
                              cx="-37.9473"
                              cy="499.5"
                              r="173"
                              stroke="white"
                            />
                            <circle
                              cx="-38.4473"
                              cy="499"
                              r="160.5"
                              stroke="white"
                            />
                            <circle
                              cx="-38.9473"
                              cy="498.5"
                              r="148"
                              stroke="white"
                            />
                            <circle
                              cx="-39.4473"
                              cy="498"
                              r="135.5"
                              stroke="white"
                            />
                            <circle
                              cx="-39.9473"
                              cy="497.5"
                              r="123"
                              stroke="white"
                            />
                            <circle
                              cx="-40.4473"
                              cy="497"
                              r="110.5"
                              stroke="white"
                            />
                            <circle
                              cx="-40.9473"
                              cy="496.5"
                              r="98"
                              stroke="white"
                            />
                            <circle
                              cx="-41.4473"
                              cy="496"
                              r="85.5"
                              stroke="white"
                            />
                          </g>
                        </g>
                        <path
                          d="M49.9369 7.86984L22.3695 24.9071C8.8077 33.2885 0.552734 48.0946 0.552734 64.0373V402C0.552734 422.435 17.1182 439 37.5527 439H257.397C269.684 439 281.641 435.03 291.488 427.682L365.644 372.341C380.061 361.582 388.553 344.649 388.553 326.66V38C388.553 17.5655 371.987 1.00004 351.553 1.00004L74.1201 1C65.5775 1 57.2037 3.3788 49.9369 7.86984Z"
                          style={{
                            transitionDuration: '0.3s',
                            stroke:
                              projectId === project.id
                                ? '#4E4E4E'
                                : 'transparent',
                          }}
                        />
                        <rect
                          x="311.553"
                          y="360"
                          width="72"
                          height="72"
                          rx="36"
                          style={{
                            transitionDuration: '0.3s',
                            fill:
                              projectId === project.id ? '#707070' : '#202020',
                          }}
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M349.475 385.922C349.904 385.492 350.601 385.492 351.03 385.922L359.831 394.722C360.26 395.151 360.26 395.848 359.831 396.277L351.03 405.078C350.601 405.507 349.904 405.507 349.475 405.078C349.045 404.648 349.045 403.951 349.475 403.522L356.397 396.6H337.053C336.445 396.6 335.953 396.107 335.953 395.5C335.953 394.892 336.445 394.4 337.053 394.4H356.397L349.475 387.477C349.045 387.048 349.045 386.351 349.475 385.922Z"
                          fill="white"
                        />
                        <defs>
                          <clipPath id="clip0_1098_1171">
                            <rect
                              width="388"
                              height="438"
                              fill="white"
                              transform="translate(13.5527 14)"
                            />
                          </clipPath>
                          <clipPath id="clip1_1098_1171">
                            <rect
                              x="-13.4473"
                              y="353"
                              width="69"
                              height="69"
                              rx="34.5"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <img
                        className="position-absolute"
                        src={project.logo}
                        alt="Project"
                      />

                      <h4 className="position-absolute h5 h4-xxl text-white fw-lighter">
                        {project.title}
                      </h4>
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              <NoData message="Nenhum projeto cadastrado" />
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default YourWorks;
