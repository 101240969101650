import React, { useEffect, useMemo, useState } from 'react';
import { RiArrowRightUpLine, RiInstagramLine } from 'react-icons/ri';
import { ImWhatsapp } from 'react-icons/im';
import { BsWhatsapp } from 'react-icons/bs';
import { scroller } from 'react-scroll';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, PoweredBy } from './styles';

import logo from '~/assets/logos/logo-footer.svg';
import devsigner from '~/assets/logos/devsigner.svg';

const Footer: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [selection, setSelection] = useState('start');

  useEffect(() => {
    if (location.hash) {
      setSelection(location.hash.replace('#', ''));
      history.push(process.env.PUBLIC_URL);
    }
  }, [history, location.hash]);

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(selection, {
        smooth: 'easeInOutQuart',
        offset: -100,
      });
    }, 100);
  }, [selection]);

  const LinkComponent = useMemo<any>(() => {
    if (location.pathname !== '/') {
      return NavLink;
    }

    return AnchorLink;
  }, [location.pathname]);

  return (
    <>
      <Container className="py-5 px-5 px-lg-0">
        <div className="container pb-5 pb-lg-0">
          <div className="row">
            <div className="col-lg-6">
              <img src={logo} alt="logo" className="logo" />
              <div className="mt-5">
                <p className="mb-5 mb-lg-0 h5">
                  Exploramos oportunidades não óbvias para
                  <br className="d-none d-lg-block" /> que você não deixe
                  dinheiro na mesa
                </p>
              </div>
            </div>

            <div className="col-lg-3">
              <LinkComponent
                to={`${process.env.PUBLIC_URL}/`}
                href="#start"
                className="h4 h5-lg h4-xl d-block mb-4"
              >
                Início
              </LinkComponent>
              <LinkComponent
                to={`${process.env.PUBLIC_URL}/#about-us`}
                href="#about-us"
                className="h4 h5-lg h4-xl d-block mb-4"
              >
                Sobre nós
                <RiArrowRightUpLine
                  className="ms-3"
                  size={25}
                  color="#707070"
                />
              </LinkComponent>
              <LinkComponent
                to={`${process.env.PUBLIC_URL}/#projects`}
                href="#projects"
                className="h4 h5-lg h4-xl d-block mb-4"
              >
                Portifólio
                <RiArrowRightUpLine
                  className="ms-3"
                  size={25}
                  color="#707070"
                />
              </LinkComponent>
              <LinkComponent
                to={`${process.env.PUBLIC_URL}/#contact`}
                href="#contact"
                className="h4 h5-lg h4-xl d-block mb-4"
              >
                Contato
              </LinkComponent>
            </div>
            <div className="col-lg-3 social-media mt-5 mt-lg-0 mb-5 mb-lg-0">
              <h3 className="h4 mb-3">Redes</h3>
              <div className="d-flex align-items-center">
                <a
                  href="https://www.instagram.com/muoveprojetos/"
                  target="_blank"
                  title="Instagram"
                  rel="noreferrer"
                >
                  <RiInstagramLine size={34} color="#939393" />
                </a>

                {/* <a href="/#" target="_blank" title="LinkedIn" rel="noreferrer">
                  <ImLinkedin2 size={34} color="#939393" />
                </a> */}
                <a
                  href="https://api.whatsapp.com/send?phone=5511912906767&text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Muove.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
                  target="_blank"
                  title="Whatsapp"
                  rel="noreferrer"
                >
                  <ImWhatsapp size={34} color="#939393" />
                </a>
              </div>
            </div>
            <PoweredBy className="col-12 mt-4 text-center text-lg-start">
              <small className="text-white">
                Desenvolvido por:{' '}
                <a
                  href="https://devsigner.com.br/"
                  target="_blank"
                  className=""
                  rel="noreferrer"
                >
                  <img src={devsigner} alt="Devsigner Systems" />
                </a>
              </small>
            </PoweredBy>
          </div>
        </div>
        <div className="position-fixed btn-wtt d-flex align-items-center">
          <a
            href="https://api.whatsapp.com/send?phone=5511912906767&text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Muove.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
            className="text-wtt"
            target="_blank"
            rel="noreferrer"
          >
            Olá, tudo bem?
            <br />
            gostaria de saber mais?
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=5511912906767&text=Ol%C3%A1!%20Vim%20pelo%20site%20da%20Muove.%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
            target="_blank"
            rel="noreferrer"
            title="Whatsapp"
            className="btn-wpp"
          >
            <BsWhatsapp color="#fff" />
          </a>
        </div>
      </Container>
    </>
  );
};

export default Footer;
