import React, { useEffect, useState } from 'react';
import { SlArrowLeft } from 'react-icons/sl';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from './styles';
import api from '~/services/api';

interface IProject {
  id: number;
  title: string;
  link: string;
}

interface IParams {
  category: string;
  slug: string;
}

const SelectWork: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const [title, setTitle] = useState('');
  const [project, setProject] = useState({} as IProject);

  useEffect(() => {
    api.get(`projects/${params.slug}`).then((response) => {
      setTitle(response.data.title);
      setProject(response.data);
    });
  }, [params.slug]);

  return (
    <Container className="">
      <div className="container position-relative">
        <div className="line l-4" />
        <div className="line l-50" />
        <div className="line l-96" />
        <div className="row">
          <div className="col-12 mb-4 mt-5 pb-5 px-4 px-lg-3">
            <h2 className="d-flex align-items-center fw-normal text-white h2 display-lg-5 mt-5 pt-5">
              <button
                onClick={() => history.goBack()}
                type="button"
                className="bg-transparent border-0 me-4"
              >
                <SlArrowLeft size={25} color="#fff" />
              </button>{' '}
              {title}
            </h2>
          </div>
        </div>
        <div className="row position-relative">
          <div className="col-12 px-4 px-lg-3">
            {Object.keys(project).length > 0 && (
              <iframe title={project.title} src={project.link} width="100%" />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SelectWork;
