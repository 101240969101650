import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 16px;
  border: 1px solid #202020;
  background: #202020;
  padding: 100px 0;

  > div.pe-none {
    opacity: 0.7;
  }

  h2 {
    color: #f9f9f9;
    opacity: 0.7;
  }
`;
