import styled, { css } from 'styled-components';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header<IMenuProps>`
  font-family: 'Zen Dots', sans-serif;
  top: 0;
  background: transparent;
  z-index: 100;

  .menu-fix {
    width: 100%;
    height: 65px;
    position: fixed;
    z-index: 1000;
    transition-duration: 0.3s;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: ${(props) =>
      props.active ? 'rgba(32, 32, 32)' : 'rgba(32, 32, 32, 0.4)'};
    backdrop-filter: blur(12px);
    top: 0;
  }

  img {
    transition: 0.3s;
    height: 30px;
  }

  @media screen and (min-width: 991px) {
    .menu-fix {
      width: 100%;
      height: 70px;
    }

    img {
      height: 36px;
    }
  }
`;

export const Menu = styled.div<IMenuProps>`
  > button {
    width: 25px;
    height: 25px;

    span {
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: #fff;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        margin: 6px 0px;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -15px;
          }
        `}
    }
  }

  .menu-group {
    transition-duration: 0.3s;
    width: 100%;
    position: absolute;
    left: ${(props) => (props.active ? 0 : 1000)}px;
    background-color: ${(props) =>
      props.active ? 'rgba(32, 32, 32)' : 'transparent'};
    min-height: calc(100vh - 65px);
    top: 65px;

    .menu {
      min-height: calc(100vh - 65px);

      .btn-menu {
        transition-duration: 0.3s;
        color: #dadada;

        position: relative;

        :after {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          bottom: -10px;
          transition-duration: 0.3s;
        }
      }

      /* .btn-menu.active  */
      .btn-menu:hover {
        color: #fff;
        :after {
        }
      }
    }
  }

  @media screen and (min-width: 991px) {
    .menu-group {
      width: auto;
      position: unset;
      background-color: transparent;
      min-height: unset;
      backdrop-filter: unset;

      .menu {
        min-height: unset;

        .btn-menu {
          color: #dadada;

          :after {
            content: '';
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            bottom: -10px;
            transition-duration: 0.3s;
          }
        }

        /* .btn-menu.active,  */
        .btn-menu:hover {
          :after {
            background-color: #17c672;
          }
        }
      }
    }
  }
`;
