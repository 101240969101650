import styled from 'styled-components';

export const Container = styled.div`
  a {
    z-index: 2;
  }
  a img {
    width: auto;
    height: 27%;
    top: 35%;
    left: 12%;
  }

  a h3 {
    top: 55%;
    left: 17%;
  }

  a p {
    top: 65%;
    left: 17%;
  }

  a h4 {
    bottom: 20%;
    left: 12%;
    padding-right: 20px;
  }

  svg {
    width: 100%;
    height: auto;
  }

  ::before {
    position: absolute;
    content: '';
    width: 774px;
    height: 774px;
    top: 20%;
    right: 10%;
    border-radius: 774px;
    background: rgba(40, 242, 145, 0.1);
    filter: blur(500px);
  }

  .container::before {
    position: absolute;
    content: '';
    width: 774px;
    height: 774px;
    top: 40%;
    left: 8%;
    border-radius: 774px;
    background: rgba(40, 242, 145, 0.1);
    filter: blur(500px);
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;

    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 31.25%,
      rgba(255, 255, 255, 0) 78.13%,
      rgba(255, 255, 255, 0.3) 100%
    );
  }

  .l-4 {
    left: 4%;
  }

  .l-50 {
    left: 50%;
  }

  .l-96 {
    left: 96%;
  }

  .container {
    padding-bottom: 10%;
  }

  @media screen and (min-width: 992px) {
    a img {
      top: 24%;
    }
  }

  @media screen and (min-width: 1200px) {
    a img {
      top: 35%;
    }
  }

  @media screen and (max-width: 991px) {
    overflow: hidden;
  }
`;
