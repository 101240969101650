import styled from 'styled-components';

export const Container = styled.div`
  p {
    color: #a7a7a7;
    letter-spacing: -1.2px;
  }
  ::before {
    position: absolute;
    content: '';
    width: 774px;
    height: 774px;
    top: 20%;
    right: 10%;
    border-radius: 774px;
    background: rgba(40, 242, 145, 0.1);
    filter: blur(500px);
  }

  .container::before {
    position: absolute;
    content: '';
    width: 774px;
    height: 774px;
    top: 40%;
    left: 8%;
    border-radius: 774px;
    background: rgba(40, 242, 145, 0.1);
    filter: blur(500px);
  }

  iframe {
    border-radius: 15px;
    height: 63vw;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;

    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 31.25%,
      rgba(255, 255, 255, 0) 78.13%,
      rgba(255, 255, 255, 0.3) 100%
    );
  }

  .l-4 {
    left: 4%;
  }

  .l-50 {
    left: 50%;
  }

  .l-96 {
    left: 96%;
  }

  .container {
    padding-bottom: 10%;
  }

  .imgs {
    border-radius: 23px;
    background: #054d2a;
    z-index: 2;
    img {
      border-radius: 14px;
    }
  }

  .imgs img:nth-child(1) {
    width: 55%;
    box-shadow: 0px 4px 36.5px 0px rgba(0, 0, 0, 0.25);
  }
  .imgs img:nth-child(2) {
    width: 45%;
    margin-top: 0rem;
    box-shadow: -15px 12px 38.5px 0px rgba(0, 0, 0, 0.25);
  }

  @media screen and (max-width: 991px) {
    overflow: hidden;

    .imgs img:nth-child(1) {
      width: 100%;
    }
    .imgs img:nth-child(2) {
      width: 90%;
      margin-top: -3rem;
    }
  }

  @media screen and (min-width: 576px) {
    iframe {
      height: 335px;
    }
  }

  @media screen and (min-width: 768px) {
    iframe {
      height: 440px;
    }
  }

  @media screen and (min-width: 992px) {
    iframe {
      height: 600px;
    }
  }

  @media screen and (min-width: 1200px) {
    iframe {
      height: 700px;
    }
  }

  @media screen and (min-width: 1400px) {
    iframe {
      height: 800px;
    }
  }
`;
