import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';
import YourWorks from '~/pages/YourWorks';
import SelectWork from '~/pages/SelectWork';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/portifolio/:category`}
        exact
        component={YourWorks}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portifolio/:category/:slug`}
        component={SelectWork}
      />
    </Switch>
  );
};

export default routes;
