import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.footer`
  background: #000;

  .logo {
    width: 147px;
  }

  p {
    color: #939393;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 300 !important;
    letter-spacing: -1.2px;
  }

  h3,
  a {
    font-family: 'Zen Dots', sans-serif;
    color: #fff;
  }

  .social-media {
    a {
      .icon-footer {
        border-radius: 50%;
        width: 56px;
        height: 56px;
      }

      :nth-child(1) {
        .icon-footer {
          background-color: #e2171c;
        }
      }

      :nth-child(2) {
        .icon-footer {
          background-color: #fbe919;
        }
      }

      :nth-child(3) {
        .icon-footer {
          background-color: #0373a9;
        }
      }

      :nth-child(4) {
        .icon-footer {
          background-color: #6e2560;
        }
      }

      + a {
        margin-left: 16px;
      }
    }
  }

  .icon-whatsapp-call {
    background: #22de57;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    position: fixed;
    bottom: 100px;
    right: 20px;
  }

  .line-footer {
    border: 1px solid #464646;
  }
  .btn-wtt {
    right: 20px;
    bottom: 20px;
    z-index: 3;
  }

  .text-wtt {
    display: none;
    z-index: 10;
    opacity: 0;
    margin-right: -50px;
    color: #000;
    border-radius: 23px 0 0 23px;
    background: #e4faee;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    padding: 15px 60px 15px 20px;
    transition: all 0.5s ease;
  }

  .btn-wtt:hover {
    .text-wtt {
      display: block;
      opacity: 1;
    }
  }

  .btn-wpp {
    position: relative;
    transition-duration: 0.3s;
    background: #29d26e;
    width: 77px;
    height: 77px;
    border-radius: 50%;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 38px;
      height: 38px;
    }

    :hover {
      background: ${darken(0.03, '#29d26e')};
    }
  }

  @media screen and (min-width: 992px) {
    .box {
      h3 {
        line-height: 48px;
      }
    }

    .icon-footer {
      width: 56px;
      height: 56px;
      svg {
        font-size: 28px;
      }
    }

    .icon-whatsapp-call {
      bottom: 10px;
      right: 100px;
    }

    .btn-wpp {
      width: 77px;
      height: 77px;

      svg {
        width: 38px;
        height: 38px;
      }
    }
  }
`;

export const PoweredBy = styled.div`
  background-color: #000;
`;
