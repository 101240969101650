import styled from 'styled-components';
import bannerAboutUs from '~/assets/banners/banner-nos.png';
import bannerTrack from '~/assets/banners/banner-track.png';
import stars from '~/assets/banners/stars.png';
import twinkling from '~/assets/banners/twinkling.png';

export const Container = styled.div`
  .btn-more {
    font-family: 'Zen Dots';
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    height: 61px;
    background: rgba(32, 32, 32, 0.4);
    padding: 0 30px;
    backdrop-filter: blur(2px);
  }

  .lt-space-5 {
    letter-spacing: 5px;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;

    z-index: 1;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 31.25%,
      rgba(255, 255, 255, 0) 78.13%,
      rgba(255, 255, 255, 0.3) 100%
    );
  }

  .l-4 {
    left: 4%;
  }

  .l-50 {
    left: 50%;
  }

  .l-96 {
    left: 96%;
  }
`;

export const Hero = styled.div`
  .title {
    width: 100%;
    height: 100%;
    top: 0px;
    background: linear-gradient(180deg, rgba(1, 44, 35, 0) 0%, #00241c 100%);
  }
`;

export const Banner = styled.video`
  max-height: 100vh;
  //position: relative;
  object-fit: cover;

  height: 700px;

  @media screen and (min-width: 992px) {
    height: auto;
  }
`;

export const AboutUs = styled.div`
  background-image: url(${bannerAboutUs});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  //padding-top: 10%;
  iframe {
    border-radius: 15px;
    height: 65vw;
  }

  .dashboard {
    overflow: hidden;
    height: calc(100% - 186px);
    border-radius: 15px;
  }

  .container {
    padding-top: 10%;
  }

  .bg-hand {
    background-color: #0e5f38;
    width: 51px;
  }

  .bg-track {
    border-radius: 40px 40px 0px 0px;
    background-image: url(${bannerTrack});
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    position: relative;
    z-index: 2;
  }
  .track {
    color: #fff;

    font-family: 'Zen Dots';
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 5px;
    white-space: nowrap;
    animation: scroll-left 30s linear infinite;
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(17px);
    }
    100% {
      transform: translateX(-1764px);
    }
  }

  @media screen and (min-width: 450px) {
    .dashboard {
      height: calc(100% - 192px);
    }
  }

  @media screen and (min-width: 500px) {
    iframe {
      height: 64vw;
    }
    .dashboard {
      height: calc(100% - 190px);
    }
  }

  @media screen and (min-width: 576px) {
    iframe {
      height: 337px;
    }

    .dashboard {
      height: 276px;
    }
  }

  @media screen and (min-width: 769px) {
    iframe {
      height: 438px;
    }

    .dashboard {
      height: 377px;
    }
  }

  @media screen and (min-width: 992px) {
    background-position: bottom;
    iframe {
      height: 357px;
    }

    .dashboard {
      overflow: hidden;
      height: 296px;
      border-radius: 15px;
    }

    .track {
      font-size: 30px;
    }

    @keyframes scroll-left {
      0% {
        transform: translateX(35px);
      }
      100% {
        transform: translateX(-2390px);
      }
    }
  }

  @media screen and (min-width: 1200px) {
    iframe {
      height: 416px;
    }
    .dashboard {
      height: 355px;
    }
  }

  @media screen and (min-width: 1400px) {
    iframe {
      height: 475px;
    }

    .dashboard {
      height: 414px;
    }
  }
`;

export const Projects = styled.div`
  //padding-top: 10%;
  padding-bottom: 7%;
  position: relative;

  a img {
    width: auto;
    height: 110px;
    top: 35%;
    left: 17%;
  }

  a h3 {
    top: 55%;
    left: 17%;
  }

  a p {
    top: 62%;
    left: 17%;
  }

  a h4 {
    bottom: 25%;
    left: 17%;
  }

  .container {
    padding-top: 10%;
  }

  .width-projects {
    min-width: 1000px;
  }

  .background-stars {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    pointer-events: none;
    background-color: #000;
  }
  .background-stars .stars {
    background-image: url(${stars});
    background-size: 70em;
    background-position: center center;
    opacity: 0.6;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .background-stars .twinkling {
    background: url(${twinkling}) center top;
    animation: move-twink-back 200s linear infinite;
  }
  .background-stars .lighter-bg {
    background-color: rgba(245, 247, 250, 0.075);
  }

  @keyframes move-twink-back {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -10000px 5000px;
    }
  }

  @media screen and (min-width: 992px) {
    .width-projects {
      min-width: auto;
    }
  }
`;

export const Works = styled.div`
  background: #121213;
  //padding-top: 8%;
  padding-bottom: 13%;
  position: relative;
  button {
    font-family: 'Zen Dots';
    padding: 5px 0px;
    letter-spacing: -1.2px;
    border: none;
    border-bottom: 2px solid transparent;

    :hover {
      border-bottom: 2px solid #17c672;
    }
  }

  p,
  li {
    color: #939393;
    font-family: 'Poppins';

    font-weight: 400;
    letter-spacing: -1.2px;
  }

  .container {
    padding-top: 10%;
  }

  .active {
    border-bottom: 2px solid #17c672;
  }

  @media screen and (min-width: 992px) {
    button {
      font-size: 14px;
    }
    p,
    li {
      font-size: 16px;
      /* max-height: 400px;
      overflow: auto; */
      padding-right: 10px;
    }
    svg {
      width: 100%;
    }
  }

  @media screen and (min-width: 1200px) {
    button {
      font-size: 16px;
    }
    p,
    li {
      font-size: 18px;
    }
    svg {
      width: 433px;
    }
  }

  @media screen and (min-width: 1400px) {
    button {
      font-size: 19px;
    }
  }
`;

export const Contact = styled.div`
  background: #121213;
  //padding: 5rem;
  position: relative;

  a,
  button {
    font-family: 'Inter', 'sans-serif';
  }

  .form {
    border-radius: 20px;
    background: #101010;
  }

  label {
    display: block;
  }

  .container {
    padding: 2rem;
  }

  .bg-circle {
    position: absolute;
    left: 50%;

    transform: translate(-50%);
    width: 100%;
    height: 956px;
    border-radius: 956px;
    background: rgba(40, 242, 145, 0.1);

    filter: blur(150px);
  }

  .bg-contact {
    z-index: 2;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    border: 1px solid #373636;

    background: rgba(21, 21, 21, 0.57);

    box-shadow: 0px 4px 17.4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);

    img:nth-child(1) {
      bottom: 0px;
      right: -10px;
    }

    img:nth-child(2) {
      top: -10px;
      right: -10px;
      left: unset;
    }
  }

  .btn-back {
    top: 20px;
    left: 3rem;
  }

  @media screen and (min-width: 992px) {
    .container {
      padding: 5rem;
    }
    .bg-circle {
      width: 600px;
      height: 600px;
      top: -10%;
    }

    .bg-contact img:nth-child(2) {
      top: -10px;
      left: -30px;
      right: unset;
    }
  }

  @media screen and (min-width: 1400px) {
    .bg-circle {
      width: 956px;
      height: 956px;
      top: -35%;
    }
  }
`;
